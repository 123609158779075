<template>
    <div class="sign-post-container">
				<AccountCardsSignPost
						class="o-auth-account__card o-auth-account__small"
						v-for="(signPost, index) in signPosts"
						:key="index"
						:title="signPost.title"
						:description="signPost.description"
						:to="signPost.to"
						:icon="signPost.icon"
						:bg="signPost.bg"
					/>
		</div>
</template>

<script setup>
const signPosts = ref([
	{
		title: "Customer Support",
		description: "For help and assistance, or to amend your booking call our friendly team on  <b>01524 701508</b>",
		icon: "fa-regular fa-circle-question fa-2xl",
		bg:"#F3F2ED"
	},
]);
</script>
